import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Global } from "@emotion/react"
import theme from "../../gatsby-plugin-theme-ui"
import { Themed } from "theme-ui"
import { Header, Hero, Section, ProjectItem } from "../../components"
import { SectionWrap, ProjectList } from "../../components/styles"
import { globalStyles } from "../../templates/styles"
import { StaticImage } from "gatsby-plugin-image"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import Seo from "../../components/seo"
import AppStores from "../../components/AppStores.js"
import SearchBox from "../../components/SeachBox.js"
var allProjects = [
  {
    name: "Der, Die & Das Rules",
    link: "/articles/der-die-das-gender-rules",
    alt: "Project One",
    image: (
      <StaticImage
        style={{ width: "100%", height: "100%" }}
        src="../../images/icon.png"
        alt="A dinosaur"
        placeholder="blurred"
        layout="fixed"
        objectFit={"cover"}
        width={400}
        height={400}
      />
    ),
    badge: "English",
  },
  {
    name: "Der, Die & Das Regeln",
    link: "#",
    alt: "Project Two",
    image: (
      <StaticImage
        style={{ width: "100%", height: "100%" }}
        src="../../images/icon.png"
        alt="A dinosaur"
        placeholder="blurred"
        layout="fixed"
        objectFit={"cover"}
        width={400}
        height={400}
      />
    ),
    badge: "app",
  },
]

var allShowcases = [
  {
    name: "Mehr erfahren",
    link: "/de/showcase",
    alt: "Learn more link",
    image: (
      <StaticImage
        style={{ width: "100%", height: "100%" }}
        src="../../images/icon.png"
        alt="Der Die Das Icon"
        placeholder="blurred"
        layout="fixed"
        objectFit={"cover"}
        width={400}
        height={400}
      />
    ),
    badge: "Deutsch",
  },
  {
    name: "Der, Die & Das Regeln",
    link: "/articles/der-die-das-gender-rules",
    alt: "Project One",
    image: (
      <StaticImage
        style={{ width: "100%", height: "100%" }}
        src="../images/icon.png"
        alt="der,die,das rules"
        placeholder="blurred"
        layout="fixed"
        objectFit={"cover"}
        width={400}
        height={400}
      />
    ),
    badge: "English",
  },
  {
    name: "Substantive durchsuchen",
    link: "/de/browse",
    alt: "Browse nouns link",
    image: (
      <StaticImage
        style={{ width: "100%", height: "100%" }}
        src="../../images/letters.jpg"
        alt="Letters picture"
        placeholder="blurred"
        layout="fixed"
        objectFit={"cover"}
        width={400}
        height={400}
      />
    ),
    badge: "Deutsch",
  },
]

const HomeTemplate = () => {
  const [open, setOpen] = React.useState(false)
  const [emailAddress, setEmailAddress] = React.useState("no-spam@spam.com")

  const onOpenModal = e => {
    e.preventDefault()
    setOpen(true)
  }
  const onCloseModal = () => setOpen(false)
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          siteTitleShort
          siteAuthor
          siteLogoText
          siteUrl
          siteLanguage
          siteDescription
          siteKeywords
        }
      }
      dataJson {
        hero {
          imageAlt
        }
        work {
          content
          heading
        }
        about {
          heading
          content
        }
      }
    }
  `)
  const projectsItems = allProjects
  const { hero } = data.dataJson

  return (
    <div>
      <Seo
        title={"Der Die Das Train - Der Die Das: Deutsch lernen"}
        description={
          "Der Die Das Train ist ein Spiel mit deutschen Substantiven und ihren Artikel (der, die und das) und hilft Ihnen dabei, diese zu lernen und sich zu merken, welcher Artikel zu welchem Substantiv gehört."
        }
        lang={"de"}
        keywords={
          "der,die,das,artikel,grammatik,lernen,deutsch,substantive,training,spiel,substantiv,substantiv,vokabular,ios,android,app"
        }
      />
      <Global styles={globalStyles} />
      <Header prefix={"de"} logoTxt={"Der Die Das Train"} />
      <SectionWrap>
        <Hero imageAlt={hero.imageAlt} id="hero" />
        <SearchBox prefix="de"></SearchBox>

        <div
          style={{
            display: "flex",
            width: "100%",
            backgroundColor: "white",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "1em",
            paddingVertical: "2em",
          }}
        >
          <StaticImage
            src="../../images/mirror/1.png"
            className="showcase-img"
            alt="phone mockup"
            placeholder={"blurred"}
            fit={"contain"}
            height={750}
          />
          <StaticImage
            src="../../images/mirror/de/2.png"
            className="showcase-img"
            alt="phone mockup"
            placeholder={"blurred"}
            fit={"contain"}
            height={750}
          />
          <div
            style={{
              display: "flex",
              width: "100%",

              justifyContent: "center",
              marginBottom: "30%",
              padding: "1em",
              position: "absolute",
              zIndex: 1,
            }}
          >
            <AppStores prefix={"de"}></AppStores>
          </div>
        </div>

        {true === "true" ? (
          <Section backgroundColor={theme.colors.white} style={{}} id="work">
            <Themed.h2>{"The Rules"}</Themed.h2>
            <Themed.p>{"Learn the basic rules of genders"}</Themed.p>
            {projectsItems && (
              <ProjectList>
                {projectsItems.map(
                  ({ name, link, alt, badge, image }, index) => (
                    <ProjectItem
                      key={name + index}
                      image={image}
                      alt={alt}
                      name={name}
                      badge={badge}
                      link={link}
                    />
                  )
                )}
              </ProjectList>
            )}
          </Section>
        ) : null}

        <Section backgroundColor={theme.colors.white} id="work">
          <div className={"body-container"}>
            <Themed.h2>
              {"Spiele dich zur Meisterschaft der deutschen Sprache!"}
            </Themed.h2>
            <Themed.p>
              <span style={{ fontWeight: "bold" }}>Der Die Das Train</span>
              {
                " ist eine fesselnde mobile App, die entwickelt wurde, um Benutzern zu helfen, deutsche Substantive und ihre entsprechenden Artikel („der“, „die“ und „das“) zu meistern. Verfügbar im App Store für iOS und bei Google Play für Android, bietet dieses interaktive Spiel eine unterhaltsame und effektive Möglichkeit, die deutsche Grammatik zu lernen und einen der schwierigsten Teile für Deutschlernende zu bewältigen."
              }
            </Themed.p>
          </div>
          {allShowcases && (
            <ProjectList>
              {allShowcases.map(({ name, link, alt, badge, image }, index) => (
                <ProjectItem
                  key={name + index}
                  image={image}
                  alt={alt}
                  name={name}
                  badge={badge}
                  link={link}
                />
              ))}
            </ProjectList>
          )}
        </Section>

        <Section
          backgroundColor={theme.colors.secondary}
          id="about"
          light
          enableWave={true}
          waveBottom={false}
        >
          <div
            class="icon-bar"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "10rem",
            }}
          >
            <div
              style={{
                maxWidth: "500px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="icon-container">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/der.die.das.train/"
                  class="fa fa-instagram"
                  title={"Vist Our Instagram"}
                >
                  <StaticImage
                    src="../../images/instagram-brands.svg"
                    alt={"Instagram Icon"}
                    style={{ color: "#f0d723" }}
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={50}
                  />
                </a>
                <p>Instagram</p>
              </div>
              <div className="icon-container">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/Der.Die.Das.Train"
                  class="fa fa-facebook"
                  title={"Vist Our Facebook"}
                >
                  <StaticImage
                    src="../../images/facebook-f-brand.svg"
                    alt={"Facebook Icon"}
                    style={{ color: "#f0d723" }}
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={50}
                  />
                </a>
                <p>Facebook</p>
              </div>
            </div>
          </div>
          <div
            class="icon-bar"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                maxWidth: "500px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="icon-container">
                <a
                  href="https://der-die-das-train.com/terms-and-conditions"
                  class="fa fa-instagram"
                  title={"Terms and Conditions"}
                >
                  <StaticImage
                    src="../../images/balance-scale-right-solid.svg"
                    alt={"Terms and Conditions Icon"}
                    style={{ color: "#f0d723" }}
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={50}
                  />
                </a>
                <p>T & Ks</p>
              </div>
              <div className="icon-container">
                <a
                  href="https://der-die-das-train.com/privacy-policy"
                  class="fa fa-facebook"
                  title={"Privacy Policy"}
                >
                  <StaticImage
                    src="../../images/user-lock-solid.svg"
                    alt={"Privacy Icon"}
                    style={{ color: "#f0d723" }}
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={50}
                  />
                </a>
                <p>Datenschutz</p>
              </div>
              <div className="icon-container">
                <a
                  href="http://der-die-das-train.com"
                  class="fa fa-facebook"
                  title={"Contact"}
                  onClick={e => {
                    e.preventDefault()

                    setEmailAddress("support@der-die-das-train.com")

                    onOpenModal(e)
                  }}
                >
                  <StaticImage
                    src="../../images/envelope-open-regular.svg"
                    alt={"Contact Icon"}
                    style={{ color: "#f0d723" }}
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={50}
                  />
                </a>
                <p>Kontakt</p>
              </div>
            </div>
          </div>
          <div>
            <Modal open={open} onClose={onCloseModal} center>
              <div>
                <h3>Kontakt</h3>
                <p id={"email-address"}>{emailAddress}</p>
              </div>
            </Modal>
          </div>
        </Section>
      </SectionWrap>
    </div>
  )
}

export default HomeTemplate
